import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, input, ViewContainerRef } from '@angular/core';
import { PasswordComplexityComponent } from './password-complexity/password-complexity.component';

@Directive({
  selector: '[appPasswordComplexity]'
})
export class PasswordComplexityDirective {

  private overlayRef?: OverlayRef;
  private componentRef?: ComponentRef<PasswordComplexityComponent>

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLInputElement>,
    private viewContainerRef: ViewContainerRef
  ) {}


  @HostListener('focus')
  onFocus(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 15,
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: false,
      panelClass: 'z-50'

    });


    const portal = new ComponentPortal(
      PasswordComplexityComponent,
      this.viewContainerRef
    );
    const componentRef = this.overlayRef.attach(portal);

    componentRef.setInput('password',this.elementRef.nativeElement.value)

    this.componentRef = componentRef;
  }


  @HostListener('input')
  onInput(): void {
    if (this.overlayRef && this.overlayRef.hasAttached() && this.componentRef) {
        this.componentRef.setInput('password',this.elementRef.nativeElement.value)
    }
  }

  @HostListener('blur')
  onBlur(): void {
   this.disposeOverlay();
  }

  ngOnDestroy(): void {
    this.disposeOverlay();
  }

  private disposeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }
}

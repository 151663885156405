import { Component, computed, effect, input, model, OnInit } from '@angular/core';

@Component({
  selector: 'password-complexity',
  template:`<div class="popover">
  <p>Password must:</p>
  <ul class="status-list">
    <li [class.valid]="hasNumber()">Have one number</li>
    <li [class.valid]="hasUpperCase()">Have one uppercase character</li>
    <li [class.valid]="hasLowerCase()">Have one lowercase character</li>
    <li [class.valid]="hasSpecialCharacter()">Have one special character</li>
    <li [class.valid]="hasMinLength()">Have 8 characters minimum</li>
  </ul>
</div>
`,
styles: `
  @reference "tailwindcss";

  .popover {
    @apply min-w-[200px] bg-gray-700 p-4 w-fit text-white rounded-md;
  }

  .popover::before {
    content: '';
    @apply absolute top-1/2 -translate-y-1/2 -left-[10px]
      w-0 h-0
      border-t-[10px] border-t-transparent
      border-b-[10px] border-b-transparent
      border-r-[10px] border-r-gray-700;
  }

  .status-list {
    @apply list-none p-0 m-0;
  }

  .status-list li {
    @apply flex items-center py-[10px];
  }

  .status-list li::before {
    /* Default red dot */
    content: '';
    @apply inline-block w-[10px] h-[10px] rounded-full mr-[10px] bg-red-500;
  }

  .status-list li.valid::before {
    /* Green dot for valid items */
    @apply bg-green-500;
  }
`
})
export class PasswordComplexityComponent {

  constructor() {
  }


  password = input.required<string>();

  hasNumber = computed(() => {
    const password = this.password();
    console.log(password)
    return /\d/.test(password);
  })

  hasUpperCase = computed(() => {
    const password = this.password();
    return /[A-Z]/.test(password);
  })

  hasLowerCase = computed(() => {
    const password = this.password();
    return /[a-z]/.test(password);
  })


  hasSpecialCharacter = computed(() => {
    const password = this.password();
    return /[!@#$%^&*(),.?":{}|<>]/.test(password);
  })

  hasMinLength = computed(() => {
    const password = this.password();
    return password.length >= 8;
  })


}
